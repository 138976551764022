@import 'app.scss';
.section {
  flex-shrink: 0;
  background-color: var(--background-color);
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
  overflow: hidden;
  color: var(--text-color);
}

.section.white {
  --text-color: var(--color-fg-default);
  --background-color: var(--color-bg-default);

  + .section.white {
    padding-top: 0;
  }
}

.section.gray {
  --text-color: var(--color-fg-alternative);
  --background-color: var(--color-bg-alternative);

  + .section.gray {
    padding-top: 0;
  }
}
