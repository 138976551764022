$breakpoints: (
  xs: 480px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
); // Copied from tailwindcss

@mixin from-breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Please make sure it is defined in `$grid-breakpoints` map.';
  }
}

@mixin until-breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (width < #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Please make sure it is defined in `$grid-breakpoints` map.';
  }
}
