@import 'app.scss';
.container {
  grid-column: var(--section-width, 1 / -1);
  margin-bottom: spacing(6);
  width: 100%;

  /**
  * Remove margin-bottom from last child of this container
  */
  > :last-child {
    margin-bottom: 0;
  }

  @include from-breakpoint(md) {
    grid-column: var(--section-width, 1 / span 10);
  }

  @include from-breakpoint(lg) {
    grid-column: var(--section-width, 1 / span 6);
  }
}

.centered {
  @include from-breakpoint(md) {
    grid-column: var(--section-width, 2 / span 10);
  }

  @include from-breakpoint(lg) {
    grid-column: var(--section-width, 4 / span 6);
  }
}

.text {
  margin-bottom: spacing(6);

  @include from-breakpoint(lg) {
    margin-bottom: spacing(8);
  }
}

.subtitle {
  @include typography-paragraph-lg;
}

.inStore {
  .title {
    line-height: 0.9;
    font-size: 3.125rem;
  }

  .subtitle,
  .text {
    line-height: 1.3;
    font-size: 1.5rem;
  }

  .text {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.fullWidth {
  grid-column: 1 / -1;
}

.addSpaceForButtons {
  grid-column: 1 / -1;
  margin-bottom: 60px;
  padding-top: 35px;
}
