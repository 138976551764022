/**
 * Typography styling
 * Shared base styles for (responsive) typography sizes
 */

/**
* PARAGRAPHS 
*/
@mixin typography-paragraph-xs {
  // Not present in styleguide but used for some things
  line-height: 120%;
  font-size: 0.875rem; // 14px
  font-weight: 400;
}

@mixin typography-paragraph-sm {
  line-height: 128%;
  font-size: 1rem; // 16px
  font-weight: 400;
}

@mixin typography-paragraph-md {
  line-height: 120%;
  font-size: 1.125rem; // 18px
  font-weight: 400;
}

@mixin typography-paragraph-lg {
  line-height: 120%;
  font-size: 1.25rem; // 20px
  font-weight: 500;
}

/**
* HEADINGS 
* Design was pretty inconsistent. These values correspond to values that are used in the Styleguide & pageparts section of the design. This is leading.
*/

@mixin typography-heading-1 {
  line-height: 1.1111;
  font-size: 2.5rem; // 40px
  font-weight: 500;

  @include from-breakpoint(md) {
    line-height: 1.05;
    font-size: 4.5rem; // 72px
  }
}

@mixin typography-heading-2 {
  line-height: 1.05;
  font-size: 1.8125; // 29px
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 2.25rem; // 36px
  }
}

@mixin typography-heading-3 {
  line-height: 1.2;
  font-size: 1.125rem; // 18px
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 1.5rem; // 24px
  }
}

@mixin typography-heading-4 {
  // Not present in styleguide but used for some things
  line-height: 1.2;
  font-size: 1.25rem; // 20px
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 1.375rem; // 22px. Yes, this mobile version is larger than heading 3 on mobile and smaller on desktop
  }
}

/* OTHERS */

@mixin typography-button {
  line-height: 111%;
  font-size: 1.125rem;
  font-weight: 500;
}
